import './App.css';
import 'tachyons';
import Application from './Components/Application';



function App() {
  return (
    <div>
       <Application />       
    </div>
  );
}

export default App;
